exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-history-tsx": () => import("./../../../src/pages/history.tsx" /* webpackChunkName: "component---src-pages-history-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-imprint-tsx": () => import("./../../../src/pages/legal/imprint.tsx" /* webpackChunkName: "component---src-pages-legal-imprint-tsx" */),
  "component---src-pages-legal-privacy-policy-tsx": () => import("./../../../src/pages/legal/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-news-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/news/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-news-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-presentations-tsx": () => import("./../../../src/pages/presentations.tsx" /* webpackChunkName: "component---src-pages-presentations-tsx" */),
  "component---src-pages-roadmap-tsx": () => import("./../../../src/pages/roadmap.tsx" /* webpackChunkName: "component---src-pages-roadmap-tsx" */)
}

